export default {
  transaction_id: "ID transakcji",
  service_date: "Data usługi",
  change_plan_button_title: "Zmień plan",
  trial_period: "Okres testowy",
  minimum_value_for_payment_error: "Minimalna wartość to 2.",
  choose_tenant_dialog_title: "Wybierz firmę, do której chcesz się zalogować",
  select: "Wybierz",
  choose_tenant_dialog_forgotten_password_title:
    "Wybierz firmę, w której chcesz zmienić hasło",
  user_uses_email_already:
    "Wybrany adres e-mail jest wykorzystywany przez klienta lub innego pracownika.",
  fields_required: "* - pola wymagane",
  add_resources_with_plus:
    "Brak zasobów. Dodaj zasoby, klikając „+” po prawej stronie.",
  add_categories_with_plus:
    "Brak kategorii. Dodaj kategorię, klikając „+” po prawej stronie.",
  email_sent: "Wiadomość e-mail została wysłana",
  sms_sent: "Wiadomość SMS została wysłana",
  payment_method_missing:
    "Nie masz podłączonej metody płatności. Przejdź do zakładki „Rozliczenia” i dodaj metodę płatności.",
  invoice_data_missing:
    "Nie masz uzupełnionych danych do faktury VAT. Przejdź do zakładki „Rozliczenia” i uzupełnij dane.",
  my_web_template: "Moja strona www",
  no_websites_info:
    "Nie masz jeszcze dodanych stron www, kliknij poniżej, aby dodać pierwszą.",
  no_website_info_button: "Dodaj stronę www",
  no_mobile_apps_info:
    "Nie masz jeszcze projektu aplikacji mobilnej, kliknij poniżej, aby dodać pierwszą.",
  no_mobile_apps_info_button: "Stwórz aplikację mobilną",
  my_mobile_app_template: "Moja aplikacja mobilna",
  company_name_2: "Nazwa firmy lub imię i nazwisko",
  go_back: "Cofnij",
  new_feature: "Nowa funkcja w Calendesk",
  notifications_banner_text:
    "Spersonalizuj powiadomienia e-mail, SMS, i push w prosty sposób.",
  edit_now: "Edytuj teraz",
  notification_templates_list: "Szablony",
  notification_templates_list_header: "Panel / Powiadomienia",
  booking_prepayment: "Nowa rezerwacja - wymagana płatność",
  booking_prepayment_info:
    "Powiadomienie jest wysyłane, jeśli rezerwowana usługa wymaga płatności lub jeśli utworzona rezerwacja ma status „Wymagana przedpłata”. Powiadomienie nie jest wysyłane, jeśli rodzaj powiadomień „Potwierdzenie rezerwacji” jest wyłączony lub jeśli opcja „Nie informuj o stworzeniu wizyty” została wybrana podczas tworzenia rezerwacji.",
  booking_created: "Potwierdzenie rezerwacji",
  booking_created_info:
    "Powiadomienie jest wysyłane po utworzeniu rezerwacji ze statusem „Zaakceptowana” oraz po dokonaniu płatności. Powiadomienie nie jest wysyłane, jeśli rodzaj powiadomień „Potwierdzenie rezerwacji” jest wyłączony lub jeśli opcja „Nie informuj o stworzeniu wizyty” została wybrana podczas tworzenia rezerwacji.",
  booking_updated: "Zmiana rezerwacji",
  booking_updated_info:
    "Powiadomienie jest wysyłane po zmianie rezerwacji (tj. dokonano zmiany statusu, daty lub godziny spotkania, pracownika, klienta, lokalizacji lub usługi). Powiadomienie nie jest wysyłane, jeśli rodzaj powiadomień „Zmiana rezerwacji” jest wyłączony lub jeśli opcja „Nie informuj o zmianie/usunięciu wizyty” została wybrana podczas zmiany rezerwacji.",
  booking_deleted: "Usunięcie rezerwacji",
  booking_deleted_info:
    "Powiadomienie jest wysyłane po usunięciu lub anulowaniu rezerwacji. Powiadomienie nie jest wysyłane, jeśli rodzaj powiadomień „Usunięcie rezerwacji” jest wyłączony lub jeśli opcja „Nie informuj o zmianie/usunięciu wizyty” została wybrana podczas usuwania rezerwacji.",
  booking_reminder: "Przypomnienie o rezerwacji",
  booking_reminder_info:
    "Powiadomienie jest wysyłane do rezerwacji ze statusem „Zaakceptowana” zgodnie z ustawionym czasem przed wykonaniem rezerwacji. Powiadomienie nie jest wysyłane, jeśli rodzaj powiadomień „Przypomnienie o rezerwacji” jest wyłączony.",
  account_activate: "Aktywacja konta - e-mail powitalny",
  account_activate_info:
    "Powiadomienie jest wysyłane po samodzielnym utworzeniu konta przez klienta na stronie wygenerowanej w Calendesk.",
  "account_password-reset": "Reset hasła",
  "account_password-reset_info":
    "Powiadomienie jest wysyłane po skorzystaniu z opcji przypomnienia hasła na stronie wygenerowanej w Calendesk.",
  newsletter_subscribe: "Subskrypcja newslettera",
  newsletter_subscribe_info:
    "Powiadomienie jest wysyłane po skorzystaniu z opcji zapisu na newsletter na stronie wygenerowanej w Calendesk.",
  "simple-store-product-transaction_paid":
    "Sklep - potwierdzenie zakupu produktu",
  edited: "Edytowane",
  "simple-store-product-transaction_paid_info":
    "Powiadomienie jest wysyłane na główny adres email konta po dokonaniu zakupu produktów na stronie wygenerowanej w Calendesk. Powiadomienie nie jest wysyłane podczas dokonywaniu zakupów produktów w trakcie rezerwacji wizyty (powiadomienia dotyczące rezerwacji zawierają już te informacje).",
  notification_info:
    "Edycja powiadomień pomoże Ci dostosować treść powiadomień do swoich wymagań. Możesz skorzystać z zaawansowanej edycji powiadomień, wykorzystując tzw. zmienne lub zostawić domyślne ustawienia. Domyślne powiadomienia zawierają wszystkie niezbędne informacje, które powinien otrzymać klient i pracownik.",
  notification_templates_list_for_client: "Powiadomienia do klienta",
  notification_templates_list_for_tenant: "Powiadomienia do Twojego pracownika",
  notification_templates_list_for_tenant_info:
    "Powiadomienia do pracowników są wysyłane na adresy e-mail pracowników lub w przypadku ich braku na adres główny konta (zakładka Ustawienia / Twoja firma).",
  notification_templates_list_information:
    "W sekcji po lewej stronie możesz dostosować swoje powiadomienia. Możesz skorzystać ze zmiennych we wiadomościach. Zmienne mają format „[NAZWA]”. W miejsce całej zmiennej podstawimy wybraną wartość. To może być nazwa usługi, imię Twojego klienta itp. Wystarczy, że klikniesz niebieski przycisk obok miejsca, gdzie ustawiasz powiadomienie, aby wybrać interesującą Cię zmienną.",
  ready: "Gotowe",
  notification_templates_list_preview_title: "Podgląd przykładowych wiadomości",
  notification_template_editor_sidebar_email_title: "E-mail",
  notification_template_editor_sidebar_sms_title: "SMS",
  notification_template_editor_sidebar_push_title: "Push w telefonie",
  notification_template_editor_sidebar_text: "Tekst",
  notification_template_editor_publish_title: "Zmiana treści powiadomień",
  notification_template_editor_publish_cancel_title: "Usuń zmiany",
  notification_template_editor_publish_text:
    "Zmiana zostanie wprowadzona od razu.",
  notification_template_editor_publish_cancel_text:
    "Wprowadzone zmiany zostaną usunięte.",
  notification_template_editor_publish_warning:
    "Tej operacji nie da się cofnąć!",
  notification_template_editor_publish_confirmation_button:
    "Zmień powiadomienia",
  notification_template_editor_publish_cancel_confirmation_button:
    "Usuń zmiany",
  notification_template_editor_sidebar_restore_all: "Przywróć domyślną treść",
  notification_template_editor_sidebar_restore_all_information:
    "Domyślne powiadomienia z tej grupy zostaną przywrócone.",
  notification_template_editor_sidebar_restore_all_button: "Przywróć",

  booking_prepayment_email_subject_client:
    "Wymagana płatność: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_prepayment_email_text_client:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Twoja rezerwacja na <b>[SERVICE_NAME]</b> wymaga płatności.</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Kwota:</b><br>[BOOKING_PRICE]</p>[BOOKING_CUSTOM_FIELDS]<p><b>Brak płatności w ciągu [BOOKING_AWAITING_PAYMENT_FULL_TIME] spowoduje usunięcie rezerwacji. Kliknij poniżej, aby przejść do rezerwacji i dokonać płatności.</b></p><p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON]',
  booking_created_email_subject_client:
    "Potwierdzono: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_created_email_text_client:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Twoja rezerwacja na <b>[SERVICE_NAME]</b> została potwierdzona.</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Lokalizacja:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON][ADD_TO_CALENDAR_BUTTONS]',
  booking_created_email_subject_tenant:
    "Nowa rezerwacja: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_created_email_text_tenant:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p><b>Pojawiła się nowa rezerwacja na usługę:</b><br> [SERVICE_NAME]</p><p><b>Klient:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Pracownik:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Lokalizacja:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_updated_email_subject_client:
    "Zmiana rezerwacji: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_updated_email_text_client:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Twoja rezerwacja na <b>[SERVICE_NAME]</b> została zmieniona.</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Lokalizacja:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON][ADD_TO_CALENDAR_BUTTONS]',
  booking_updated_email_subject_tenant:
    "Zmiana rezerwacji: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_updated_email_text_tenant:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p><b>Nastąpiła zmiana w rezerwacji na usługę:</b><br> [SERVICE_NAME]</p><p><b>Klient:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Pracownik:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Lokalizacja:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_deleted_email_subject_client:
    "Rezerwacja odwołana: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_deleted_email_text_client:
    "<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Informujemy, że rezerwacji na usługę <b>[SERVICE_NAME]</b> została odwołana.</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p>",
  booking_deleted_email_subject_tenant:
    "Rezerwacja odwołana: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_deleted_email_text_tenant:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Informujemy, że rezerwacji na usługę <b>[SERVICE_NAME]</b> została odwołana.</p><p><b>Klient:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Pracownik:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',
  booking_reminder_email_subject_client:
    "Przypomnienie o rezerwacji: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_reminder_email_text_client:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Przypominamy o rezerwacji na usługę <b>[SERVICE_NAME]</b></p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Lokalizacja:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS]<p class="cd-paragraph"></p>[MANAGE_CUSTOMER_BOOKING_BUTTON]',
  booking_reminder_email_subject_tenant:
    "Przypomnienie o rezerwacji: [BOOKING_START_DATE] [BOOKING_START_TIME] - [SERVICE_NAME]",
  booking_reminder_email_text_tenant:
    '<h1>Numer rezerwacji: [BOOKING_ID]</h1><hr/><p>Cześć,</p><p>Przypominamy o rezerwacji na usługę:</p><p> [SERVICE_NAME]</p><p><b>Klient:</b><br>[CUSTOMER_FULL_NAME]</p><p><b>Pracownik:</b><br>[EMPLOYEE_FULL_NAME]</p><p><b>Data i godzina:</b><br>[BOOKING_START_DATE] [BOOKING_START_TIME] ([BOOKING_TIME_ZONE])</p><p><b>Lokalizacja:</b><br>[BOOKING_LOCATION] [BOOKING_ONLINE_MEETING_URL]</p>[BOOKING_CUSTOM_FIELDS]<p class="cd-paragraph"></p>[MANAGE_EMPLOYEE_BOOKING_BUTTON]',

  booking_created_push_subject_client: "Dziękujemy za dokonanie rezerwacji",
  booking_created_push_text_client:
    "[SERVICE_NAME] w [COMPANY_NAME] w dniu [BOOKING_START_DATE] o godz. [BOOKING_START_TIME].",
  booking_created_push_subject_tenant: "[COMPANY_NAME] - Nowa rezerwacja",
  booking_created_push_text_tenant:
    "Usługa: [SERVICE_NAME], Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godzina: [BOOKING_START_TIME].",
  booking_updated_push_subject_client: "Nastąpiła zmiana w Twojej rezerwacji",
  booking_updated_push_text_client:
    "[SERVICE_NAME] w [COMPANY_NAME] w dniu [BOOKING_START_DATE] o godz. [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_updated_push_subject_tenant: "[COMPANY_NAME] - Zmiana rezerwacji",
  booking_updated_push_text_tenant:
    "Usługa: [SERVICE_NAME], Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godzina: [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_deleted_push_subject_client: "Rezerwacja została usunięta",
  booking_deleted_push_text_client:
    "[SERVICE_NAME] w [COMPANY_NAME] w dniu [BOOKING_START_DATE] o godz. [BOOKING_START_TIME].",
  booking_deleted_push_subject_tenant:
    "[COMPANY_NAME] - Rezerwacja została usunięta",
  booking_deleted_push_text_tenant:
    "Usługa: [SERVICE_NAME], Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godzina: [BOOKING_START_TIME].",
  booking_reminder_push_subject_client: "Przypomnienie o rezerwacji",
  booking_reminder_push_text_client:
    "[SERVICE_NAME] w [COMPANY_NAME] w dniu [BOOKING_START_DATE] o godz. [BOOKING_START_TIME].",
  booking_reminder_push_subject_tenant:
    "[COMPANY_NAME] - Przypomnienie o rezerwacji",
  booking_reminder_push_text_tenant:
    "Usługa: [SERVICE_NAME], Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godzina: [BOOKING_START_TIME].",

  booking_created_sms_text_client:
    "Dziekujemy za dokonanie rezerwacji na [SERVICE_NAME] w [COMPANY_NAME] w dniu [BOOKING_START_DATE] o godz. [BOOKING_START_TIME].",
  booking_created_sms_text_tenant:
    "[COMPANY_NAME] - Nowa rezerwacja: [SERVICE_NAME]. Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godzina: [BOOKING_START_TIME].",
  booking_updated_sms_text_client:
    "Rezerwacja na [SERVICE_NAME] w [COMPANY_NAME] zostala zmieniona. Dnia [BOOKING_START_DATE] o godz. [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_updated_sms_text_tenant:
    "[COMPANY_NAME] - Zmiana rezerwacji: [SERVICE_NAME]. Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godz.: [BOOKING_START_TIME], status: [BOOKING_STATUS].",
  booking_deleted_sms_text_client:
    "Rezerwacja na [SERVICE_NAME] w [COMPANY_NAME] zostala usunieta. Dnia [BOOKING_START_DATE] o godz. [BOOKING_START_TIME].",
  booking_deleted_sms_text_tenant:
    "[COMPANY_NAME] - Usunieto rezerwacje: [SERVICE_NAME]. Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godz.: [BOOKING_START_TIME].",
  booking_reminder_sms_text_client:
    "Przypominamy o wizycie w [COMPANY_NAME] w dniu [BOOKING_START_DATE] godz. [BOOKING_START_TIME]. Tel.: [COMPANY_PHONE].",
  booking_reminder_sms_text_tenant:
    "[COMPANY_NAME] - Przypomnienie o rezerwacji na [SERVICE_NAME]. Klient: [CUSTOMER_FULL_NAME], data: [BOOKING_START_DATE], godz.: [BOOKING_START_TIME].",

  account_activate_email_subject_client:
    "Potwierdzenie rejestracji w serwisie [COMPANY_NAME]",
  account_activate_email_text_client:
    "<h1>Witaj w serwisie [COMPANY_NAME]</h1><p>Cieszymy się, że chcesz do nas dołączyć. Tylko jeden krok dzieli Cię od możliwości używania narzędzi, ułatwiających korzystanie z naszych usług. Dokończ rejestrację, aktywując swoje konto.</p>",
  account_activate_email_cta_button_client: "Aktywuj konto",
  "account_password-reset_email_subject_client":
    "Twoje hasło w systemie [COMPANY_NAME]",
  "account_password-reset_email_text_client":
    "<h1>Twoje hasło w systemie [COMPANY_NAME]</h1><p>Kliknij poniżej, aby ustawić swoje hasło do konta w [COMPANY_NAME].</p>",
  "account_password-reset_email_cta_button_client": "Ustaw hasło",
  newsletter_subscribe_email_subject_client:
    "Potwierdzenie zapisania się na newsletter w [COMPANY_NAME]",
  newsletter_subscribe_email_text_client:
    '<h1>Potwierdzenie zapisania się na newsletter w [COMPANY_NAME]</h1><p class="cd-paragraph">Kliknij poniżej, aby potwierdzić chęć zapisania się na newsletter w [COMPANY_NAME]. Jeśli ta wiadomość trafiła do Ciebie przez pomyłkę, możesz ją zignorować.</p>',
  newsletter_subscribe_email_cta_button_client: "Potwierdzam",

  "simple-store-product-transaction_paid_email_subject_client":
    "Dziękujemy za zakup [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_client":
    '<h1>To jest potwierdzenie otrzymania środków</h1><p class="cd-paragraph">Dziękujemy za zakup [PRODUCT_NAME].</p><p>Cena: [PRODUCT_PRICE], Liczba: [PRODUCT_QUANTITY].</p><p>Dodatkowe informacje: [PRODUCT_DESCRIPTION]</p>',

  "simple-store-product-transaction_paid_email_subject_tenant":
    "Nowy zakup [PRODUCT_NAME]",
  "simple-store-product-transaction_paid_email_text_tenant":
    '<h1>Nowy zakup [PRODUCT_NAME]</h1><p class="cd-paragraph">Twój produkt [PRODUCT_NAME] został kupiony przez klienta [CUSTOMER_FULL_NAME].</p><p>Cena: [PRODUCT_PRICE], Liczba: [PRODUCT_QUANTITY].</p><p>Dodatkowe informacje: [PRODUCT_DESCRIPTION]</p>',

  employee_example_name: "Jan",
  employee_example_surname: "Pracowity",
  employee_example_fullname: "Jan Pracowity",
  customer_example_name: "Anna",
  customer_example_surname: "Kowalska",
  customer_example_fullname: "Anna Kowalska",
  service_example_name: "Usługa ABC",
  service_example_name_2: "Konsultacja online",
  phone_number_is_missing: "Brak numeru telefonu",
  company_address_is_missing: "Brak adresu firmy",
  sms_title: "Wiadomość",
  email_subject: "Temat",
  push_now_indicator: "teraz",

  variable_customer_full_name: "Imię i nazwisko klienta",
  variable_customer_id: "Numer ID klienta",
  variable_customer_name: "Imię klienta",
  variable_customer_surname: "Nazwisko klienta",
  variable_customer_email: "E-mail klienta",
  variable_customer_phone: "Telefon klienta",
  variable_employee_id: "Numer ID pracownika",
  variable_employee_name: "Imię pracownika",
  variable_employee_email: "E-mail pracownika",
  variable_employee_phone: "Telefon pracownika",
  variable_employee_surname: "Nazwisko pracownika",
  variable_employee_full_name: "Imię i nazwisko pracownika",
  variable_booking_id: "Numer ID rezerwacji",
  variable_booking_start_date: "Data rezerwacji",
  variable_booking_start_time: "Godzina rezerwacji",
  variable_booking_end_date: "Data zakończenia rezerwacji",
  variable_booking_end_time: "Godzina zakończenia rezerwacji",
  variable_service_id: "Numer ID usługi",
  variable_service_name: "Nazwa usługi",
  variable_booking_price: "Cena usługi",
  variable_company_name: "Nazwa firmy",
  variable_company_phone: "Telefon firmy",
  variable_company_address: "Adres firmy",
  variable_booking_status: "Status rezerwacji",
  variable_booking_payment_status: "Status płatności rezerwacji",
  variable_newsletter_email: "Adres e-mail",
  variable_unsubscribe_url: "Wypisz mnie url",
  variable_product_id: "Numer ID produktu",
  variable_product_name: "Nazwa produktu",
  variable_product_description: "Dodatkowe informacje o produkcie",
  variable_product_price: "Cena produktu",
  variable_product_quantity: "Liczba sztuk produktu",
  variable_product_list: "Lista zakupionych produktów",
  variable_booking_location: "Lokalizacja",
  variable_booking_time_zone: "Strefa czasowa",
  variable_booking_recurrence_description: "Opis cyklicznej rezerwacji",
  variable_online_meeting_url: "Link do spotkania online",
  variable_booking_control_url: "Adres URL rezerwacji",
  variable_booking_created_by_user_id: "Numer ID tworzącego",
  variable_booking_updated_by_user_id: "Numer ID ostatnio edytującego",
  variable_booking_created_by: "Nazwa tworzącego",
  variable_booking_updated_by: "Nazwa ostatnio edytującego",
  variable_booking_custom_fields: "Pola formularza rezerwacji",
  add_variable_info_text: "Kliknij tutaj, aby dodać zmienną",
  rename: "Zmień nazwę",
  hide: "Ukryj",
  change_url: "Zmień linkowanie",
  back: "Wstecz",
  your_pictures: "Twoja galeria",
  photo_library_unsplash: "Biblioteka zdjęć (bezpłatna)",
  search_for_photos: "Szukaj wśród milionów zdjęć",
  search_for_photos_hint:
    "Unsplash pozwala na szukanie tylko w języku angielskim.",
  photos_by_unsplash: "Zdjęcia z Unsplash",
  photo_by_on_unsplash: "Autor zdjęcia @{name} z Unsplash",
  unsplash_photo_added_to_gallery:
    "Zdjęcie jest już dostępne w Twojej galerii.",
  for_example_unsplash: "np. flowers",
  new_website_builder_title: "Nowy edytor stron www",
  website_builder_banner_text:
    "Już jest! Stwórz swoją stronę www w nowym edytorze!",
  check_now: "Sprawdź teraz",

  // Color picker
  cp_alert_p1: "Wybrałeś za niski kontrast!",
  cp_alert_p2: "Element będzie słabo widoczny.",

  // Website builder
  wb_website_builder: "Kreator strony www",
  wb_move_up: "Przenieś do góry",
  wb_move_down: "Przenieś w dół",
  wb_auto_save_info: "Zmiany są zapisywane automatycznie",
  wb_pages: "Podstrony",
  wb_add_new_section: "Dodaj sekcję",
  wb_select_new_section: "Wybierz sekcję",
  wb_add_new_section_info: "Wybierz sekcję z menu po lewej stronie",
  wb_add_new_page: "Dodaj podstronę",
  wb_add_page_dialog_type_page_name_label: "Nazwa np. Usługi",
  wb_add_page_dialog_type_page_name_blog_label: "Nazwa np. Blog",
  wb_add_page_dialog_type_page_description:
    "Opis (widoczny dla wyszukiwarek np. Google)",
  wb_add_page_dialog_type_page_description_hint:
    "Wpisz unikalny, krótki opis zawartości swojej strony. Ten opis będzie widoczny w wynikach wyszukiwarki, takich jak Google, pod tytułem Twojej strony.",
  wb_add_page_dialog_type_page_path_label: "Adres po domenie np. usługi",
  wb_add_page_dialog_type_page_path_blog_label: "Adres po domenie np. blog",
  wb_add_page_dialog_type_main_page: "Strona główna",
  wb_add_page_dialog_type_page: "Podstrona",
  wb_add_page_dialog_type_redirect: "Przekierowanie (zewnętrzny link)",
  wb_add_page_dialog_type_blog_handy: "Blog",
  wb_add_page_dialog_type_redirect_url: "Adres www przekierowania",
  wb_add_page_dialog_type_blog_handy_id: "BlogHandy Id np. 609wl61ZYL0ArTAFR23",
  wb_blog_handy_description:
    "BlogHandy to zewnętrzna platforma, która umożliwia w prosty sposób prowadzenie bloga. BlogHandy można w prosty sposób zintegrować ze stroną www w Calendesk.",
  wb_blog_handy_info_1:
    "Aby dodać blog do swojej strony Calendesk, postępuj zgodnie z poniższymi instrukcjami:",
  wb_blog_handy_info_2:
    "Kliknij poniżej i zarejestruj się na platformie BlogHandy.",
  wb_blog_handy_info_3:
    "Skopiuj identyfikator Bloga z twojego konta BlogHandy i wklej go do poniższego pola.",
  wb_blog_handy_info_4:
    "Gratulacje! 🎉 Każdy post na blogu opublikowany na BlogHandy automatycznie pojawi się na Twojej stronie Calendesk.",
  wb_blog_handy_signup_button_title: "Zarejestruj się w BlogHandy",
  wb_general: "Ogólne",
  wb_style: "Styl",
  wb_section: "Sekcja strony",
  wb_color_primary: "Przyciski i istotne elementy",
  wb_color_bg: "Pierwsze tło",
  wb_color_text: "Tekst na pierwszym tle",
  wb_color_bg_2: "Drugie tło",
  wb_color_text_2: "Tekst na drugim tle",
  wb_google_tag_id: "Google Tag ID",
  wb_website_description: "Opis strony dla robotów (SEO)",
  wb_title: "Nagłówek",
  wb_text: "Treść",
  wb_subtitle: "Podtytuł",
  wb_description: "Opis",
  wb_image: "Grafika",
  wb_image_1: "Grafika 1",
  wb_image_2: "Grafika 2",
  wb_image_3: "Grafika 3",
  wb_image_4: "Grafika 4",
  wb_background: "Tło",
  wb_category_section_navbar_1: "Nagłówek z logo",
  wb_category_section_navbar_2: "Nagłówek bez logo",
  wb_category_section_footer: "Stopka",
  wb_category_section_employees: "Pracownicy",
  wb_category_section_services: "Usługi",
  wb_category_section_booking: "Rezerwacja wizyty",
  wb_category_section_about_us: "O nas",
  wb_category_section_feature: "Uniwersalne",
  wb_category_section_review: "Opinie",
  wb_category_section_other: "Inne",
  wb_category_section_header: "Nagłówek",
  wb_category_section_newsletter: "Newsletter",
  wb_category_section_form: "Formularz kontaktowy",
  wb_category_section_cta: "Wezwanie do działania (CTA)",
  wb_decrease_section: "Pomniejsz",
  wb_increase_section: "Powiększ",
  wb_company_name: "Nazwa firmy",
  wb_login_modal: "Logowanie",
  wb_login_subtitle: "Podtytuł",
  wb_sign_up: "Załóż konto",
  wb_login: "Zaloguj się",
  wb_sign_up_modal: "Rejestracja",
  wb_menu_bookings: "Rezerwacje",
  wb_menu_payments: "Płatności",
  wb_menu_settings: "Ustawienia",
  wb_menu_logout: "Wyloguj",
  wb_forgotten_password_modal: "Przypomnienie hasła",
  wb_forgotten_password_confirmation_modal: "Potwierdzenia wysłania maila",
  wb_forgotten_password_title: "Tytuł",
  wb_forgotten_password_subtitle: "Podtytuł",
  wb_submit_button: "Przycisk zatwierdzania",
  wb_forgotten_password_modal_confirmation_title: "Tytuł",
  wb_forgotten_password_modal_confirmation_subtitle: "Podtytuł",
  wb_company_address: "Adres",
  wb_company_email: "Adres e-mail",
  wb_company_phone: "Telefon",
  wb_menu_title: "Tytuł menu",
  wb_account_title: "Profil",
  wb_signup: "Zarejestruj",
  wb_profile: "Rezerwacje",
  wb_settings: "Ustawienia",
  wb_company_slogan: "Slogan",
  wb_service_label: "Etykieta usług",
  wb_employee_label: "Etykieta pracownika",
  wb_employee_info_label: "Tekst nad polem z pracownikami",
  wb_booking_button: "Przycisk rezerwacji",
  wb_page_list: "Edycja menu",
  wb_menu_column_1: "Menu 1",
  wb_menu_column_2: "Menu 2",
  wb_publish_confirm_dialog_title: "Opublikować stronę?",
  wb_publish_confirm_dialog_text:
    "Strona będzie widoczna w internecie w ciągu minuty.",
  wb_publish_success_text:
    "Gratulacje! Twoja strona będzie za chwilę dostępna w Internecie.",
  wb_more_pages_button: "Pozostałe strony",
  wb_sign_up_terms_info: "Wyświetlać informację o akceptacji regulaminu?",
  wb_select_data_label: "Wybierz datę",
  wb_edit_page: "Edytuj stronę",
  wb_remove_page_confirm_dialog_title: "Usunąć stronę?",
  wb_remove_page_confirm_dialog_text: "Strona zostanie usunięta.",
  wb_remove_page_success_text: "Strona została usunięta.",
  wb_remove_confirm_dialog_title: "Czy na pewno?",
  wb_remove_confirm_dialog_text: "Tej zmiany nie będzie można cofnąć!",
  wb_remove_success_text: "Usuwanie zakończone pomyślnie",
  wb_employees_title: "Nasz zespół",
  wb_employees_no_data:
    "Dodaj pracowników w panelu Calendesk, aby zobaczyć podgląd.",
  wb_services_no_data: "Dodaj usługi w panelu Calendesk, aby zobaczyć podgląd.",
  wb_employee_list: "Specjaliści",
  wb_service_list: "Usługi",
  wb_items_selector_info: "Kliknij poniżej, aby dodać elementy",
  wb_change: "Zamień",
  wb_replace_navbar: "Zastąp nagłówek",
  wb_replace_footer: "Zastąp stopkę",
  wb_main_pages_number: "Liczba głównych zakładek",
  wb_button: "Przycisk",
  wb_domain_example: "Np. mojafirma.pl",
  wb_no_domain_info: "Nie masz ustawionej domeny",
  wb_book_online: "Przycisk rezerwacji",
  wb_button_title: "Przycisk",
  wb_select_subpage: "Podstrona",
  wb_features: "Cechy",
  wb_add_button: "Dodaj przycisk",
  wb_new_button: "Nowy przycisk",
  wb_button_edit_menu_redirect: "Wybierz przekierowanie",
  wb_button_edit_menu_style: "Ustaw styl i wielkość",
  wb_button_outlined: "Zarysowany",
  wb_button_rounded: "Zaokrąglony",
  wb_button_size: "Rozmiar",
  wb_button_dark: "Ciemny",
  wb_redirect: "Przekierowanie",
  wb_add_sections_feature: "Dodaj element",
  wb_dummy_text_about_us: "O nas",
  wb_dummy_text_service: "Usługa",
  wb_dummy_text_select_service: "Wybierz usługę",
  wb_dummy_text_select_employee: "Wybierz osobę",
  wb_dummy_selected_services: "Wybrane usługi",
  wb_dummy_text_select_date: "Wybierz datę",
  wb_dummy_text_select_date_and_hour: "Wybierz datę i godzinę",
  wb_dummy_text_services: "Nasze usługi",
  wb_dummy_text_book_now: "Rezerwuj",
  wb_dummy_text_employees: "Nasz zespół",
  wb_dummy_text_more: "Zobacz więcej",
  wb_dummy_text_buy_now: "Kup teraz",
  wb_dummy_text_regulations: "Regulamin",
  wb_dummy_text_privacy_policy: "Polityka Prywatności",
  wb_dummy_text_reviews_title: "Opinie naszych klientów",
  wb_dummy_text_reviews_full_name: "Jan Kowalski",
  wb_dummy_text_review_example:
    "To miejsce to prawdziwa petarda, korzystam z ich usług od dawna i ciągle mnie zadziwiają! Zdecydowanie polecam :)",
  wb_dummy_text_booking_title:
    "Zarezerwuj wizytę w naszym miejscu w kilka minut bez wychodzenia z domu i całkowicie on-line.",
  wb_dummy_text_1:
    "Nowoczesne miejsce położone w centrum Warszawy. Zawsze do dyspozycji dla Ciebie! Pracują u nas tylko najlepsi z najlepszych. Nie zapominamy o szkoleniach i zdobywaniu wiedzy, którą możemy później się dzielić z Tobą. Oddaj się w nasze ręce. Korzystamy z nowoczesnych rozwiązań. Na wizytę możesz umówić się całkowicie online, do dyspozycji masz nowoczesną aplikację mobilną, gdzie otrzymasz najważniejsze informacje od nas.",
  wb_dummy_text_2: "Poznaj możliwości, jakie dają Ci nasze usługi",
  wb_dummy_text_3: "Co oferujemy?",
  wb_dummy_text_4: "O czym chciałbyś porozmawiać?",
  wb_dummy_text_5: "Wpisz poniżej swoje imię i nazwisko",
  wb_dummy_text_6:
    "To jest przykładowy opis usługi. Ustawisz go w panelu administratora Calendesk podczas edycji usług. Możesz wybrać dowolny tekst dla różnych usług.",
  wb_dummy_text_title: "Przykładowy tekst",
  wb_dummy_subject_title: "Wiadomość z formularza na stronie www",
  wb_dummy_form_sent_success_title:
    "Dziękujemy, formularz został przesłany prawidłowo.",
  wb_dummy_form_sent_success_description:
    "Skontaktujemy się tak szybko, jak będzie to możliwe.",
  wb_dummy_text_newsletter_1:
    "Informacje o nowych usługach oraz produktach prosto na Twój e-mail",
  wb_dummy_text_newsletter_2: "Zapisz się na newsletter i bądź na bieżąco",
  wb_dummy_text_newsletter_3: "Subskrybuj",
  wb_dummy_text_newsletter_4: "Adres e-mail",
  wb_dummy_text_newsletter_5:
    "Dziękujemy! Na Twój adres e-mail wysłaliśmy link aktywacyjny.",
  wb_dummy_text_newsletter_6: "Więcej niż myślisz",
  wb_regulations_info:
    "Treść regulaminu możesz edytować w panelu Calendesk w zakładce Narzędzia / Generator dokumentów.",
  wb_privacy_policy_info:
    "Treść polityki prywatności możesz edytować w panelu Calendesk w zakładce Narzędzia / Generator dokumentów.",
  wb_regulations_page: "Strona regulaminu",
  wb_privacy_policy_page: "Strona polityki prywatności",
  wb_login_enabled: "Logowanie włączone",
  wb_booking_confirmation: "Potwierdzenie rezerwacji",
  wb_booking_created_summary: "Potwierdzenie wysłania wiadomości",
  wb_booking_created_payment_summary: "Informacja o wymaganej płatności",
  wb_creator_chat_1:
    "Cześć &#128075;. Jestem Twoim wirtualnym asystentem tworzenia stron internetowych. Pomogę Ci w kilka minut skonfigurować stronę.",
  wb_creator_chat_2: "Wybierz rodzaj swojego biznesu",
  wb_creator_chat_3:
    "Dzięki! Pierwszy krok za nami &#128588;. Czas na wybór elementów, które mają pojawić się na Twojej nowej stronie.",
  wb_creator_chat_4:
    "Wybierz styl, który zastosujemy na stronie. Kolory będzie można później dowolnie zmieniać.",
  wb_creator_chat_5:
    "Już prawie gotowe! Możesz mieć kilka szkiców strony i zmieniać je w zależności od zapotrzebowania &#129321;. Jak chcesz nazwać aktualny szkic?",
  wb_creator_chat_6:
    "Gotowe &#128079;! Świetnie Ci to wyszło. Przygotowuję stronę, daj mi chwilę... &#128640;&#128640;",
  wb_creator_chat_6a: "Będzie wspaniała &#129321;",
  wb_creator_chat_6b: "Już prawie kończę ...",
  wb_creator_chat_6c: "Jeszcze tylko kilka linijek kodu i zrobione ...",
  wb_select_draft_name: "Jak chcesz nazwać obecny szkic strony?",
  wb_draft_name_text_field_label: "Nazwa strony",
  wb_chat_with_us: "Czat z obsługą",
  wb_selected_n_items: "Wybrane elementy: @{amount}.",
  wb_business_1: "Salon fryzjerski",
  wb_business_2: "Salon Spa",
  wb_business_3: "Gabinet kosmetyczny",
  wb_business_4: "Dietetyk",
  wb_business_5: "Korepetycje",
  wb_business_6: "Opieka nad dziećmi",
  wb_business_7: "Wydarzenia biznesowe",
  wb_business_8: "Urząd miasta",
  wb_business_9: "Usługi finansowe",
  wb_business_10: "Escape room",
  wb_business_11: "Usługi prawne",
  wb_business_12: "Medycyna",
  wb_business_13: "Szkoła jazdy",
  wb_business_14: "Fotograf",
  wb_business_15: "Psycholog",
  wb_business_16: "Usługi konsultingowe",
  wb_business_17: "Coaching",
  wb_business_18: "Opieka nad zwierzętami",
  wb_business_19: "Architektura wnętrz",
  wb_business_20: "Usługi sprzątania",
  wb_business_21: "Sport",
  wb_business_22: "Trener personalny",
  wb_business_23: "Siłownia",
  wb_business_24: "Fitness",
  wb_business_25: "Joga",
  wb_business_26: "Klub tenisowy",
  wb_business_27: "Lokale do spotkań",
  wb_business_28: "Restauracja",
  wb_business_29: "Fizjoterapia",
  wb_business_30: "Inne",
  wb_website_element_1: "Logowanie i rejestracja klientów",
  wb_website_element_2: "Rezerwacje online",
  wb_website_element_3: "Usługi",
  wb_website_element_4: "O nas",
  wb_website_element_5: "Zespół",
  wb_website_element_6: "Regulamin i polityka prywatności",
  wb_website_element_7: "Galeria zdjęć",
  wb_website_element_8: "Newsletter",
  wb_website_element_tooltip_1:
    "Umożliwimy zakładanie konta oraz logowanie Twoim klientom.",
  wb_website_element_tooltip_2:
    "Dodamy sekcję z możliwością dokonywania rezerwacji online.",
  wb_website_element_tooltip_3: "Dodamy sekcję i podstronę z Twoimi usługami.",
  wb_website_element_tooltip_4: "Dodamy sekcję z informacjami o Twojej firmie.",
  wb_website_element_tooltip_5:
    "Dodamy sekcję i podstronę z Twoimi pracownikami.",
  wb_website_element_tooltip_6:
    "Dodamy podstronę z regulaminem oraz polityką prywatności. Całość zaczytamy z panelu Calendesk.",
  wb_website_element_tooltip_7: "Dodamy sekcję z galerią zdjęć.",
  wb_website_element_tooltip_8: "Dodamy sekcję z newsletterem.",
  wb_website_style_1: "Morski Sztorm",
  wb_website_style_2: "Morska Stal",
  wb_website_style_3: "Pastelowy Puder",
  wb_website_style_4: "Zamglony Mak",
  wb_website_style_5: "Leśny Świat",
  wb_website_style_6: "Miodowy Zmierzch",
  wb_website_style_7: "Słoneczny uśmiech",
  wb_website_style_8: "Czerwony pocałunek",
  wb_website_style_9: "Zdrowy zielony",
  wb_website_style_10: "Deszczowa chmura",
  wb_website_style_11: "Jagody w Czekoladzie",
  wb_website_style_12: "Pomarańczowy Pył",
  wb_draft_name_example: "Moja strona - @{template}",
  wb_default_login_text: "Aby móc zarezerwować wizytę, musisz się zalogować.",
  wb_default_forgotten_password_title: "Każdy czasami zapomina swojego hasła",
  wb_default_forgotten_password_subtitle:
    "Podaj adres e-mail użyty podczas rejestracji w celu wysłania instrukcji dotyczącej zmiany hasła.",
  wb_default_forgotten_password_modal_confirmation_title:
    "Sprawdź swoją skrzynkę",
  wb_default_forgotten_password_modal_confirmation_subtitle:
    "Jeśli adres e-mail istnieje w systemie, to właśnie wysłaliśmy na niego wiadomość z instrukcjami zmiany hasła.",
  wb_default_booking_created_summary:
    "Na twój adres e-mail wysłaliśmy potwierdzenie rezerwacji.",
  wb_default_address: "Warszawa, al. Jerozolimskie 1, 00-001",
  wb_default_email: "jan.kowalski@calendesk.com",
  wb_default_product_name: "Przykładowy produkt",
  wb_default_product_description: "To są dodatkowe informacje o produkcie.",
  wb_default_phone: "+48 22 200 20 20",
  wb_default_menu_title: "Menu",
  wb_default_profile_title: "Profil",
  wb_main_page_name: "Strona główna",
  wb_main_page_url: "strona-glowna",
  wb_employees_page_name: "O nas",
  wb_team_page_name: "Nasz zespół",
  wb_employees_page_url: "o-nas",
  wb_team_page_url: "nasz-zespol",
  wb_services_page_name: "Usługi",
  wb_services_page_name_2: "Oferta",
  wb_services_page_url: "uslugi",
  wb_services_page_url_2: "oferta",
  wb_regulations_page_name: "Regulamin",
  wb_regulations_page_url: "regulamin",
  wb_privacy_policy_page_name: "Polityka prywatności",
  wb_privacy_policy_page_url: "polityka-prywatnosci",
  wb_dark_mode: "Tryb ciemny",
  wb_facebook: "Adres URL - Facebook",
  wb_instagram: "Adres URL - Instagram",
  wb_twitter: "Adres URL - Twitter",
  wb_youtube: "Adres URL - YouTube",
  wb_section_missing_question:
    "Brakuje sekcji? Napisz do nas, zrobimy ją dla Ciebie!",
  wb_employees_select_section_info:
    "Opisy pracowników oraz ich zdjęcia możesz edytować w panelu Calendesk.",
  wb_edit_employees_button_title: "Edytuj pracowników",
  wb_services_select_section_info:
    "Opisy usług oraz ich zdjęcia możesz edytować w panelu Calendesk.",
  wb_edit_services_button_title: "Edytuj usługi",
  wb_reload_button_title: "Odśwież",
  wb_category_section_photo_gallery: "Galeria zdjęć",
  wb_hide_arrows: "Ukryj strzałki",
  wb_hide_delimiters: "Ukryj przełączniki",
  wb_interval: "Szybkość zmiany w milisekundach",
  wb_cycle: "Przełączanie automatyczne",
  wb_invert_colors: "Odwróć kolory",
  wb_website_disabled_success:
    "Strona została wyłączona, w ciągu kilku minut przestanie być widoczna w Internecie.",
  wb_set_as_main_website: "Ustaw jako główną stronę www",
  wb_set_as_main_website_success: "Główna strona została ustawiona poprawnie.",
  wb_main_website: "Główna strona www",
  new_content_available:
    "Pojawiła się nowa aktualizacja strony, kliknij „Odśwież”, aby przeładować stronę.",
  refresh_button_title: "Odśwież",
  wb_newsletter_input_placeholder: "Podpowiedź",
  wb_newsletter_success_message: "Wiadomość o wysłaniu linku",
  newsletter_delete_success: "Poprawnie usunięto wybrane rekordy",
  remove_newsletter_record: "Usuń adres e-mail",
  remove_newsletter_records: "Usuń adresy e-mail",
  verified_at: "Zweryfikowano",
  email_is_valid: "E-mail potwierdzony",
  email_is_not_valid: "E-mail niepotwierdzony",
  newsletters: "Newsletter",
  push: "Push",
  push_tooltip: "Użytkownik korzysta z aplikacji mobilnej",
  send_customer_notification_title: "Wyślij wiadomość do klienta",
  send_customers_notification_title: "Wyślij wiadomość do klientów",
  send_employee_notification_title: "Wyślij wiadomość do pracownika",
  send_employees_notification_title: "Wyślij wiadomość do pracowników",
  send_customer_notification_number_info: "Liczba odbiorców",
  notification_type_1: "E-mail",
  notification_type_2: "SMS",
  notification_type_3: "Powiadomienie Push",
  notification_type_title: "Rodzaj powiadomienia",
  notification_subject: "Tytuł",
  notification_content: "Treść",
  send: "Wyślij",
  send_customer_notification_confirm_title:
    "Czy na pewno chcesz wysłać wiadomość do wybranych osób?",
  send_customer_notification_confirm_info:
    "Wysyłasz wiadomość typu „@{type}”. Liczba odbiorców: @{amount}.",
  download_csv_title: "Pobierz CSV",
  download_vcf_title: "Pobierz VCF (vCard)",
  download_xlsx_title: "Pobierz XLSX (Microsoft Excel)",
  download_xls_title: "Pobierz XLS (Microsoft Excel)",
  download_ods_title: "Pobierz ODS (Open Office, LibreOffice)",
  download_html_title: "Pobierz HTML",
  customer_notifications: "Historia",
  customer_type: "Odbiorca",
  customer_type_1: "Klient",
  customer_type_2: "Newsletter",
  customer_type_3: "Pracownik",
  customer_type_4: "Zewnętrzny",
  customer_type_5: "Firma",
  recipients_count_title: "Liczba odbiorców",
  display_customer_notification_title: "Podgląd wiadomości",
  expand: "Rozwiń",
  collapse: "Zwiń",
  reverse_charge_info:
    "Podatek VAT nie będzie doliczany do faktury (odwrotne obciążenie).",
  reverse_charge_info_2:
    "Podatek VAT do zapłaty z tytułu odwrotnego obciążenia.",
  employees_premium_banner_title: "Limit został osiągnięty",
  employees_premium_banner_description:
    "Przejdź na wyższy plan, aby móc dodawać pracowników bez ograniczeń.",
  users_premium_banner_title: "Limit został osiągnięty",
  users_premium_banner_description:
    "Przejdź na wyższy plan, aby móc dodawać klientów bez ograniczeń.",
  google_calendar: "Kalendarz Google",
  google_meet: "Google Meet",
  google_meet_description:
    "Ustaw lokalizację Twojej usługi w Calendesk na Google Meet, aby podczas każdej rezerwacji, system Calendesk tworzył unikalny link do spotkania. Link trafi do Ciebie oraz Twojego klienta zaraz po utworzeniu rezerwacji.",
  google_calendar_connect_info:
    "Kliknij poniżej, aby połączyć swój kalendarz Google z kalendarzem Calendesk. Po poprawnym połączeniu wszystkie Twoje wizyty oraz ich zmiany będą wysyłane do kalendarza Google. Pamiętaj, kalendarz działa tylko w jedną stronę. Jeśli dokonasz zmiany wizyty w kalendarzu Google ta zmiana nie będzie widoczna w Calendesk. Wszystkie zmiany wprowadzaj zawsze przez Calendesk.",
  google_calendar_disconnect_info:
    "Synchronizacja z kalendarzem Google jest aktywna. Kliknij poniżej, aby rozłączyć.",
  disconnect_button_title: "Rozłącz",
  plugin_only_for_employees_info:
    "Dodatek działa tylko z kontem pracownika. Musisz utworzyć konto pracownika, aby móc przyjmować rezerwacje.",
  plugin_not_active_employee_info:
    "Dodatek nie jest aktywny. Skontaktuj się z Administratorem, aby włączył dodatek.",
  google_calendar_auth_success_title: "Poprawna autoryzacja Google",
  google_calendar_disconnect_dialog_title: "Rozłącz kalendarz Google",
  google_calendar_disconnect_dialog_info:
    "Czy na pewno chcesz rozłączyć kalendarz Google? Twoje przyszłe wizyty i blokady w Calendesk nie będą już wysyłane do kalendarza Google.",
  google_calendar_disconnect_success_message:
    "Kalendarz został poprawnie rozłączony.",
  buy: "Kup",
  on_time_payment: "Płatność jednorazowa",
  plan_expired_title: "Twój aktualny plan wygasł",
  building_plans_title:
    "Włączamy wszystkie potrzebne funkcje dla Ciebie &#128640;&#128640;!",
  building_plans_subtitle: "Nie potrwa to dłużej niż kilka sekund ...",
  go_to_summary: "Przejdź do podsumowania",

  cd_base_standard: "Calendesk Standard",
  cd_base_pro: "Calendesk Pro",
  cd_base_enterprise: "Calendesk Enterprise",
  plans_description_standard:
    "Wykorzystaj możliwości Calendesk, aby usprawnić swój biznes.",
  plans_description_pro:
    "Dla najbardziej wymagających, skupionych na wzroście.",
  plans_description_enterprise:
    "Pełna elastyczność, skontaktuj się z nami, aby dowiedzieć się więcej.",
  enterprise_price_list_info: "Indywidualna umowa z rozliczeniem rocznym",
  plans_billed_annually: "+ VAT, rozliczenie roczne",
  plans_or: "albo",
  plans_month_to_month_payment: "+ VAT, rozliczenie miesięczne",
  plans_featured: "Najczęściej wybierane",

  plans_feature_name_1: "Twój adres www",
  plans_feature_lite_1: "Podstawowa subdomena: calendesk.net",
  plans_feature_lite_1_tooltip:
    "Brak możliwości ustawienia własnej domeny, adres internetowy Twojej strony będzie wyglądał następująco: www.nazwaFirmy.calendesk.net.",
  plans_feature_standard_1: "Własna domena",
  plans_feature_standard_1_tooltip:
    "Możliwość ustawienia dowolnego adresu www po wykupieniu domeny w serwisie hostingowym.",
  plans_feature_pro_1: "Własna domena",
  plans_feature_pro_1_tooltip:
    "Możliwość ustawienia dowolnego adresu www po wykupieniu domeny w serwisie hostingowym.",

  plans_feature_name_2: "Liczba stron www",
  plans_feature_standard_2: "Maksymalnie 1 strona",
  plans_feature_standard_2_tooltip:
    "Możliwość stworzenia 1 strony www z dowolną liczbą podstron dla Twojego biznesu.",
  plans_feature_pro_2: "Maksymalnie 3 strony",
  plans_feature_pro_2_tooltip:
    "Możliwość stworzenia 3 dedykowanych stron z dowolną liczbą podstron dla różnych usług, które oferujesz (np. pod SEO i SEM).",
  plans_feature_enterprise_2: "Ustalane indywidualnie",
  plans_feature_enterprise_2_tooltip:
    "Możliwość stworzenia dowolnej liczby stron dla różnych usług i różnych klientów – np. strony dla klientów indywidualnych, dla biznesu, reklamujące konkretny produkt lub usługę, strony pod Facebooka i specjalne strony pod wyszukiwarki (SEO i SEM).",

  plans_feature_name_3: "Kreator stron www",
  plans_feature_standard_3: "Tak",
  plans_feature_standard_3_tooltip:
    "Możliwość tworzenia dowolnej liczby sekcji na stronie, dostęp do szerokiego zbioru grafik oraz możliwości personalizowania strony.",
  plans_feature_pro_3: "Tak",
  plans_feature_pro_3_tooltip:
    "Możliwość tworzenia dowolnej liczby sekcji na stronie, dostęp do szerokiego zbioru grafik oraz możliwości personalizowania strony.",
  plans_feature_enterprise_3: "Tak",
  plans_feature_enterprise_3_tooltip:
    "Możliwość tworzenia dowolnej liczby sekcji na stronie, dostęp do szerokiego zbioru grafik oraz możliwości personalizowania strony.",

  plans_feature_name_4: "Aplikacja mobilna dla klientów",
  plans_feature_standard_4: "Tak",
  plans_feature_standard_4_tooltip:
    "Możliwość stworzenia prostej aplikacji mobilnej na telefon dla Twoich klientów – maksymalnie ułatw klientom dotarcie do siebie i korzystanie z Twoich usług.",
  plans_feature_pro_4: "Tak",
  plans_feature_pro_4_tooltip:
    "Możliwość stworzenia prostej aplikacji mobilnej lub zaawansowanej wersji spersonalizowanej – w pełni dopasowanej do Twoich i oczekiwań.",
  plans_feature_enterprise_4: "Tak",
  plans_feature_enterprise_4_tooltip:
    "Możliwość stworzenia prostej aplikacji mobilnej lub zaawansowanej wersji spersonalizowanej – w pełni dopasowanej do Twoich i oczekiwań.",

  plans_feature_name_5: "Powiadomienia e-mail",
  plans_feature_standard_5: "500 szt. miesięcznie",
  plans_feature_standard_5_tooltip:
    "Wysyłaj swoim klientom maile rezerwacyjne (potwierdzaj przyjęcie terminu, jego zmianę lub odwołanie), maile transakcyjne, powiadomienia i oferty specjalne.",
  plans_feature_pro_5: "1 000 szt. miesięcznie",
  plans_feature_pro_5_tooltip:
    "Wysyłaj swoim klientom maile rezerwacyjne (potwierdzaj przyjęcie terminu, jego zmianę lub odwołanie), maile transakcyjne, powiadomienia i oferty specjalne.",
  plans_feature_enterprise_5: "Ustalane indywidualnie",
  plans_feature_enterprise_5_tooltip:
    "Wysyłaj swoim klientom maile rezerwacyjne (potwierdzaj przyjęcie terminu, jego zmianę lub odwołanie), maile transakcyjne, powiadomienia i oferty specjalne.",

  plans_feature_name_6: "Powiększenie pakietu wiadomości e-mail",
  plans_feature_standard_6: "Tak",
  plans_feature_standard_6_tooltip:
    "Możliwość dokupienia dowolnej liczby w cenie 8 gr za e-mail.",
  plans_feature_pro_6: "Tak",
  plans_feature_pro_6_tooltip:
    "Możliwość dokupienia dowolnej liczby w cenie 4 gr za e-mail.",
  plans_feature_enterprise_6: "Tak",
  plans_feature_enterprise_6_tooltip:
    "Możliwość dokupienia dowolnej liczby w cenie ustalanej indywidualnej.",

  plans_feature_name_7: "Powiadomienia SMS",
  plans_feature_standard_7: "50 szt. miesięcznie",
  plans_feature_standard_7_tooltip:
    "Wysyłaj swoim klientom wiadomości SMS – potwierdzaj rezerwację, zmianę terminu lub odwołanie, informuj o promocjach, ofertach specjalnych i zniżkach.",
  plans_feature_pro_7: "100 szt. miesięcznie",
  plans_feature_pro_7_tooltip:
    "Wysyłaj swoim klientom wiadomości SMS – potwierdzaj rezerwację, zmianę terminu lub odwołanie, informuj o promocjach, ofertach specjalnych i zniżkach.",
  plans_feature_enterprise_7: "Ustalane indywidualnie",
  plans_feature_enterprise_7_tooltip:
    "Wysyłaj swoim klientom wiadomości SMS – potwierdzaj rezerwację, zmianę terminu lub odwołanie, informuj o promocjach, ofertach specjalnych i zniżkach.",

  plans_feature_name_8: "Powiększenie pakietu wiadomości SMS",
  plans_feature_standard_8: "Tak",
  plans_feature_standard_8_tooltip:
    "Możliwość dokupienia dowolnej liczby po 24 gr za SMS + pakiet specjalny 200 szt. miesięcznie w cenie 29,99 zł.",
  plans_feature_pro_8: "Tak",
  plans_feature_pro_8_tooltip:
    "Możliwość dokupienia dowolnej liczby po 19 gr za SMS + pakiet PREMIUM 250 szt. miesięcznie w cenie 29,99 zł.",
  plans_feature_enterprise_8: "Tak",
  plans_feature_enterprise_8_tooltip:
    "Możliwość dokupienia dowolnej liczby w cenie ustalanej indywidualnej.",

  plans_feature_name_10: "Płatności online i subskrypcje",
  plans_feature_standard_10: "Tak",
  plans_feature_standard_10_tooltip:
    "Realizuj płatności online – szybko, wygodnie i pewnie. Nie martw się o wydawanie reszty i puste rezerwacje, na których nic nie zarabiasz.",
  plans_feature_pro_10: "Tak",
  plans_feature_pro_10_tooltip:
    "Realizuj płatności online – szybko, wygodnie i pewnie. Nie martw się o wydawanie reszty i puste rezerwacje, na których nic nie zarabiasz.",
  plans_feature_enterprise_10: "Tak",
  plans_feature_enterprise_10_tooltip:
    "Realizuj płatności online – szybko, wygodnie i pewnie. Nie martw się o wydawanie reszty i puste rezerwacje, na których nic nie zarabiasz.",

  plans_feature_name_11: "System rezerwacji",
  plans_feature_standard_11: "Tak",
  plans_feature_standard_11_tooltip:
    "Pełna opcja rezerwacji online wraz z możliwością dokonania przez klienta adnotacji oraz pozostawienia komentarza.",
  plans_feature_pro_11: "Tak",
  plans_feature_pro_11_tooltip:
    "Pełna opcja rezerwacji online wraz z możliwością dokonania przez klienta adnotacji oraz pozostawienia komentarza.",
  plans_feature_enterprise_11: "Tak",
  plans_feature_enterprise_11_tooltip:
    "Pełna opcja rezerwacji online wraz z możliwością dokonania przez klienta adnotacji oraz pozostawienia komentarza.",

  plans_feature_name_12: "Integracja Google Calendar",
  plans_feature_standard_12: "Tak",
  plans_feature_standard_12_tooltip:
    "Połącz swoje usługi z kalendarzem Google – eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Google. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową.",
  plans_feature_pro_12: "Tak",
  plans_feature_pro_12_tooltip:
    "Połącz swoje usługi z kalendarzem Google – eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Google. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową.",
  plans_feature_enterprise_12: "Tak",
  plans_feature_enterprise_12_tooltip:
    "Połącz swoje usługi z kalendarzem Google – eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Google. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową.",

  plans_feature_name_14: "Newsletter",
  plans_feature_standard_14: "Tak",
  plans_feature_standard_14_tooltip:
    "Stwórz prosty newsletter dla klienta – przypomnij o swoich usługach, oferuj bonusy, zniżki, promocje i dodatkowe usługi.",
  plans_feature_pro_14: "Tak",
  plans_feature_pro_14_tooltip:
    "Stwórz prosty newsletter dla klienta – przypomnij o swoich usługach, oferuj bonusy, zniżki, promocje i dodatkowe usługi.",
  plans_feature_enterprise_14: "Tak",
  plans_feature_enterprise_14_tooltip:
    "Stwórz prosty newsletter dla klienta – przypomnij o swoich usługach, oferuj bonusy, zniżki, promocje i dodatkowe usługi.",

  plans_feature_name_18: "Liczba rezerwacji",
  plans_feature_standard_18: "750 rezerwacji miesięcznie",
  plans_feature_standard_18_tooltip:
    "Obsługa maksymalnie do 750 rezerwacji miesięcznie.",
  plans_feature_pro_18: "2 500 rezerwacji miesięcznie",
  plans_feature_pro_18_tooltip:
    "Obsługa maksymalnie do 2 500 rezerwacji miesięcznie.",
  plans_feature_enterprise_18: "Ustalane indywidualnie",
  plans_feature_enterprise_18_tooltip:
    "Brak limitu liczby obsługiwanych rezerwacji.",

  plans_feature_name_19: "Liczba kont pracowników",
  plans_feature_standard_19: "5 kont pracowników",
  plans_feature_standard_19_tooltip:
    "Ograniczenie do 5 użytkowników aplikacji – możliwość podstawowego ustalenia ról i poziomów dostępu.",
  plans_feature_pro_19: "15 kont pracowników",
  plans_feature_pro_19_tooltip:
    "Ograniczenie do 15 użytkowników aplikacji – możliwość podstawowego ustalenia ról i poziomów dostępu.",
  plans_feature_enterprise_19: "Ustalane indywidualnie",
  plans_feature_enterprise_19_tooltip:
    "Brak limitu liczby obsługiwanych kont – nieograniczona możliwość określania ról i wielu poziomów dostępu. Liczba obsługiwanych kont ustalana indywidualnie.",

  plans_feature_name_20: "Polityka prywatności i regulamin",
  plans_feature_standard_20: "Tak",
  plans_feature_standard_20_tooltip:
    "Wygeneruj dokumenty przy pomocy aplikacji stworzonej wspólnie z naszymi prawnikami.",
  plans_feature_pro_20: "Tak",
  plans_feature_pro_20_tooltip:
    "Wygeneruj dokumenty przy pomocy aplikacji stworzonej wspólnie z naszymi prawnikami.",
  plans_feature_enterprise_20: "Tak",
  plans_feature_enterprise_20_tooltip:
    "Wygeneruj dokumenty przy pomocy aplikacji stworzonej wspólnie z naszymi prawnikami.",

  plans_feature_name_21: "Integracja Google Meet",
  plans_feature_standard_21: "Tak",
  plans_feature_standard_21_tooltip:
    "Wygeneruj link do spotkania Google Meet od razu po dokonaniu rezerwacji.",
  plans_feature_pro_21: "Tak",
  plans_feature_pro_21_tooltip:
    "Wygeneruj link do spotkania Google Meet od razu po dokonaniu rezerwacji.",
  plans_feature_enterprise_21: "Tak",
  plans_feature_enterprise_21_tooltip:
    "Wygeneruj link do spotkania Google Meet od razu po dokonaniu rezerwacji.",

  plans_feature_name_22: "Integracja Fakturownia",
  plans_feature_standard_22: "Tak",
  plans_feature_standard_22_tooltip:
    "Wystaw fakturę VAT swojemu klientowi od razu po płatności.",
  plans_feature_pro_22: "Tak",
  plans_feature_pro_22_tooltip:
    "Wystaw fakturę VAT swojemu klientowi od razu po płatności.",
  plans_feature_enterprise_22: "Tak",
  plans_feature_enterprise_22_tooltip:
    "Wystaw fakturę VAT swojemu klientowi od razu po płatności.",

  plans_feature_name_23: "Integracja Zoom",
  plans_feature_standard_23: "Tak",
  plans_feature_standard_23_tooltip:
    "Wygeneruj link do spotkania Zoom od razu po dokonaniu rezerwacji.",
  plans_feature_pro_23: "Tak",
  plans_feature_pro_23_tooltip:
    "Wygeneruj link do spotkania Zoom od razu po dokonaniu rezerwacji.",
  plans_feature_enterprise_23: "Tak",
  plans_feature_enterprise_23_tooltip:
    "Wygeneruj link do spotkania Zoom od razu po dokonaniu rezerwacji.",

  plans_feature_name_24: "Integracja Zapier",
  plans_feature_standard_24: "Nie",
  plans_feature_standard_24_tooltip: "Opcja dostępna od planu taryfowego Pro.",
  plans_feature_pro_24: "Tak",
  plans_feature_pro_24_tooltip:
    "Połącz swoje aplikacje i zautomatyzuj przepływy pracy.",
  plans_feature_enterprise_24: "Tak",
  plans_feature_enterprise_24_tooltip:
    "Połącz swoje aplikacje i zautomatyzuj przepływy pracy.",

  plans_feature_name_25: "Dostęp do API",
  plans_feature_standard_25: "Nie",
  plans_feature_standard_25_tooltip: "Opcja dostępna od planu taryfowego Pro.",
  plans_feature_pro_25: "Tak",
  plans_feature_pro_25_tooltip:
    "Wykorzystaj dane zebrane w Calendesk w dowolnych aplikacjach, z którymi pracujesz.",
  plans_feature_enterprise_25: "Tak",
  plans_feature_enterprise_25_tooltip:
    "Wykorzystaj dane zebrane w Calendesk w dowolnych aplikacjach, z którymi pracujesz.",

  plans_feature_name_26: "Widget z kalendarzem",
  plans_feature_standard_26: "Tak",
  plans_feature_standard_26_tooltip:
    "Możliwość podłączenia systemu rezerwacji na własnej stronie www.",
  plans_feature_pro_26: "Tak",
  plans_feature_pro_26_tooltip:
    "Możliwość podłączenia systemu rezerwacji na własnej stronie www.",
  plans_feature_enterprise_26: "Tak",
  plans_feature_enterprise_26_tooltip:
    "Możliwość podłączenia systemu rezerwacji na własnej stronie www.",

  plans_feature_name_27: "Liczba unikalnych odsłon strony www miesięcznie",
  plans_feature_standard_27: "5 000 odsłon miesięcznie",
  plans_feature_standard_27_tooltip:
    "5 000 unikalnych wizyt miesięcznie na Twojej stronie www lub w kalendarzu.",
  plans_feature_pro_27: "10 000 odsłon miesięcznie",
  plans_feature_pro_27_tooltip:
    "10 000 unikalnych wizyt miesięcznie na Twojej stronie www lub w kalendarzu.",
  plans_feature_enterprise_27: "Ustalane indywidualnie",
  plans_feature_enterprise_27_tooltip:
    "Liczba unikalnych wizyt ustalana indywidualnie.",

  plans_feature_name_28: "Integracja Microsoft Outlook",
  plans_feature_standard_28: "Tak",
  plans_feature_pro_28: "Tak",
  plans_feature_standard_28_tooltip:
    "Połącz swoje usługi z kalendarzem Outlook – eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Outlook. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową.",
  plans_feature_pro_28_tooltip:
    "Połącz swoje usługi z kalendarzem Outlook – eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Outlook. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową.",
  plans_feature_enterprise_28: "Tak",
  plans_feature_enterprise_28_tooltip:
    "Połącz swoje usługi z kalendarzem Outlook – eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Outlook. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową.",

  plans_feature_name_29: "Integracja Microsoft Teams",
  plans_feature_standard_29: "Tak",
  plans_feature_standard_29_tooltip:
    "Wygeneruj link do spotkania Teams od razu po dokonaniu rezerwacji.",
  plans_feature_pro_29: "Tak",
  plans_feature_pro_29_tooltip:
    "Wygeneruj link do spotkania Teams od razu po dokonaniu rezerwacji.",
  plans_feature_enterprise_29: "Tak",
  plans_feature_enterprise_29_tooltip:
    "Wygeneruj link do spotkania Teams od razu po dokonaniu rezerwacji.",

  plans_feature_name_30: "Integracja Skype",
  plans_feature_standard_30: "Tak",
  plans_feature_standard_30_tooltip:
    "Wygeneruj link do spotkania Skype od razu po dokonaniu rezerwacji.",
  plans_feature_pro_30: "Tak",
  plans_feature_pro_30_tooltip:
    "Wygeneruj link do spotkania Skype od razu po dokonaniu rezerwacji.",
  plans_feature_enterprise_30: "Tak",
  plans_feature_enterprise_30_tooltip:
    "Wygeneruj link do spotkania Skype od razu po dokonaniu rezerwacji.",

  plans_feature_name_31: "Integracja WhatsApp",
  plans_feature_standard_31: "Tak",
  plans_feature_standard_31_tooltip:
    "Wygeneruj link do spotkania WhatsApp od razu po dokonaniu rezerwacji.",
  plans_feature_pro_31: "Tak",
  plans_feature_pro_31_tooltip:
    "Wygeneruj link do spotkania WhatsApp od razu po dokonaniu rezerwacji.",
  plans_feature_enterprise_31: "Tak",
  plans_feature_enterprise_31_tooltip:
    "Wygeneruj link do spotkania WhatsApp od razu po dokonaniu rezerwacji.",

  plans_feature_name_32: "Zaawansowane przypomnienia",
  plans_feature_standard_32: "Nie",
  plans_feature_standard_32_tooltip:
    "Podstawowe przypomnienia w formie e-maili, SMS-ów oraz powiadomień push, które można ustawić na dowolną godzinę.",
  plans_feature_pro_32: "Tak",
  plans_feature_pro_32_tooltip:
    "Zaawansowane i precyzyjne przypomnienia, które umożliwiają określenie, jakie przypomnienia, do kogo i kiedy mają być wysyłane.",
  plans_feature_enterprise_32: "Tak",
  plans_feature_enterprise_32_tooltip:
    "Zaawansowane i precyzyjne przypomnienia, które umożliwiają określenie, jakie przypomnienia, do kogo i kiedy mają być wysyłane.",

  plans_trial_faq_2_title:
    "Czy mogę zrezygnować z Calendesk w dowolnym momencie?",
  plans_trial_faq_2_description_1:
    "Jeśli chcesz anulować subskrypcję, będzie nam przykro, że odchodzisz, ale oczywiście <b>możesz to zrobić, kiedy tylko chcesz</b>. Calendesk nie zobowiązuje Cię do żadnych długoterminowych umów. Możesz korzystać z Calendesk płacąc nam co miesiąc lub co rok (otrzymujesz 20% rabatu na roczne płatności).",

  plans_trial_faq_3_title: "Czy mogę zmienić plan w dowolnym momencie?",
  plans_trial_faq_3_description_1:
    "Oczywiście, że tak. <b>Możesz migrować między planami w dowolnym momencie.</b> Nasz operator płatności przeliczy kwoty, abyś nic nie stracił na takiej migracji. Jeśli podniesiesz plan w trakcie okresu rozliczeniowego, to zostaniesz obciążony tylko za pozostały okres. Jeśli obniżysz plan w trakcie okresu rozliczeniowego, wykorzystamy pozostałą kwotę na przyszłe płatności lub zwrócimy Ci pieniądze.",

  plans_trial_faq_4_title:
    "Co stanie się po upływie okresu umowy? Czy aplikacja przedłuży się automatycznie i naliczy dodatkową opłatę za cały okres?",
  plans_trial_faq_4_description_1:
    "Tak, wybrany plan odnawia się automatycznie. Jeśli nie chcesz, aby plan się odnowił w nowym okresie, możesz go w dowolnym momencie anulować w zakładce „Rachunki i płatności”.",

  plans_trial_faq_5_title:
    "Czy utracę swoje konto i dane, gdy zapomnę przedłużyć swój okres użytkowania Calendesk?",
  plans_trial_faq_5_description_1:
    "Twoje dane, przez jakiś czas, nadal będą dostępne w naszym systemie – tak, abyś mógł spokojnie odzyskać dostęp do swojego konta i projektu, który stworzyłeś.",
  plans_trial_faq_5_description_2:
    "Po upływie okresu umowy i zablokowaniu konta nawet na kilka dni nadal masz możliwość jego odzyskania pod warunkiem dokonania opłaty.",
  plans_trial_faq_5_description_3:
    "Gdyby sprawiło Ci to trudności lub było, w jakikolwiek sposób niejasne, skontaktuj się naszym zespołem wsparcia technicznego.",

  plans_trial_faq_6_title:
    "Skąd będę wiedział, że kończy mi się okres użytkowania?",
  plans_trial_faq_6_description_1:
    "Po zalogowaniu na swoje konto aplikacji Calendesk w prawym rogu wyświetli się nazwa Twojego planu taryfowego oraz czas pozostały do jego wygaśnięcia.",
  plans_trial_faq_6_description_2:
    "Na krótko przed wygaśnięciem planu otrzymasz również powiadomienie – w postaci wyskakującego okienka – tuż po zalogowaniu, a także wyślemy do Ciebie wiadomość mailową.",

  plan_selected_title: "Gratulacje! Wybrałeś plan taryfowy @{plan}.",
  plan_selected_subtitle_1:
    "Dostosuj go do potrzeb Twojej firmy, wybierając dodatkowe pakiety!",
  plan_selected_subtitle_2:
    "Możesz je włączać i wyłączać w dowolnym momencie w menu aplikacji.",

  "plugins_sms-notifications_cd_base_lite_name": "Pakiet Kontakt",
  "plugins_sms-notifications_cd_base_lite_description":
    "Wysyłaj swoim klientom powiadomienia SMS w prosty i szybki sposób. Potwierdzaj dokonanie rezerwacji, przypominaj o wizycie i akceptuj zmiany terminu. Pakiet zawiera 150 wiadomości SMS z możliwością wielokrotnego dokupowania nowych.",
  "plugins_sms-notifications_cd_base_standard_name": "Pakiet Kontakt Pro",
  "plugins_sms-notifications_cd_base_standard_description":
    "Wysyłaj swoim klientom powiadomienia SMS w jeszcze większej ilości. Przypominaj im się specjalnymi wiadomościami z promocjami, wyjątkowymi okazjami i urodzinowymi rabatami. Zachęcaj ich do kontaktu i odwiedzania Twojej strony! Pakiet zawiera 200 wiadomości SMS z możliwością wielokrotnego dokupowania nowych.",
  "plugins_sms-notifications_cd_base_pro_name": "Pakiet Kontakt Expert",
  "plugins_sms-notifications_cd_base_pro_description":
    "Wysyłaj swoim klientom powiadomienia SMS w jeszcze większej ilości. Przypominaj im się specjalnymi wiadomościami z promocjami, wyjątkowymi okazjami i urodzinowymi rabatami. Zachęcaj ich do kontaktu i odwiedzania Twojej strony! Pakiet zawiera 250 wiadomości SMS z możliwością wielokrotnego dokupowania nowych.",

  "plugins_mobile-app-staff_cd_base_lite_name":
    "Aplikacja mobilna dla pracowników",
  "plugins_mobile-app-staff_cd_base_lite_description":
    "Daj swoim pracownikom możliwość korzystania z dedykowanej aplikacji mobilnej na ich smartfonach. Pozwól im przeglądać pracowniczy kalendarz, sprawdzać przypisane im na dany dzień zadania oraz klientów, których obsłużą. Dzięki powiadomieniom typu push na telefonie, pracownicy natychmiast otrzymają od Ciebie wszystkie niezbędne informacje.",

  plugins_booking_cd_base_lite_name: "Pakiet Rezerwacja",
  plugins_booking_cd_base_lite_description:
    "Dodaj do swojej aplikacji możliwość tworzenia w pełni funkcjonalnego systemu rezerwacji dla klientów. Zaoferuj im możliwość wyboru konkretnych wariantów usług (np. mycie, strzyżenie, czesanie) oraz dostęp do grafiku Twoich pracowników – niech sami wybiorą, do kogo chcą się zapisać.",

  "plugins_google-calendar_cd_base_standard_name": "Kalendarz Google",
  "plugins_google-calendar_cd_base_standard_description":
    "Kontroluj wydarzenia na bieżąco, eksportuj wszystkie wizyty, zlecenia i spotkania do kalendarza Google. Spraw by były widoczne dla Twoich pracowników i klientów. Ułatwiaj im maksymalnie życie i zwiększaj swoją skuteczność biznesową. Twórz przypomnienia, listy zadań i plan dnia – dodawaj do nich wydarzenia z Facebooka i przypominaj się swoim klientom.",

  plugins_mobile_cd_base_lite_name: "Pakiet Mobile",
  plugins_mobile_cd_base_lite_description:
    "Spraw, by Twoja strona www była w pełni dostosowana do obsługi urządzeń mobilnych (smartfony i tablety). Dzięki temu pakietowi w prosty sposób uzyskasz pełną kompatybilność.",

  "plugins_mobile-app-customers-pro_cd_base_standard_name": "Pakiet Mobile Pro",
  "plugins_mobile-app-customers-pro_cd_base_standard_description":
    "Stwórz w pełni profesjonalną firmową aplikację na urządzenia mobilne. Pozwól swoim klientom ściągnąć na telefon aplikację z Twoim logiem i zawartością, o której sam zdecydujesz. Zastanów się nad projektem, określ styl, kolor i rodzaj czcionek. Wybierz funkcjonalności i zakres oferowanych usług – decyduj sam od początku do końca.",
  "plugins_mobile-app-customers-pro_cd_base_pro_name": "Pakiet Mobile Expert",
  "plugins_mobile-app-customers-pro_cd_base_pro_description":
    "Stwórz w pełni profesjonalną firmową aplikację na urządzenia mobilne. Pozwól swoim klientom ściągnąć na telefon aplikację z Twoim logiem i zawartością, o której sam zdecydujesz. Zastanów się nad projektem, określ styl, kolor i rodzaj czcionek. Wybierz funkcjonalności i zakres oferowanych usług – decyduj sam od początku do końca.",

  plugins_management_cd_base_lite_name: "Pakiet Management",
  plugins_management_cd_base_lite_description:
    "Odblokuj nieograniczony dostęp do bazy wiedzy marketingowej. Poznaj skuteczne, proste i łatwe w zastosowaniu porady, które pomogą Ci lepiej wykorzystywać możliwości Calendesk i rozwijać swój biznes. Czytaj artykuły, komentarze, studia i dowiaduj się o istotnych faktach na bieżąco.",

  plugins_support_cd_base_standard_name: "Indywidualna Opieka Ekspercka",
  plugins_support_cd_base_standard_description:
    "Dodaj do swojej aplikacji możliwość korzystania z profesjonalnej pomocy. Twój indywidualny konsultant techniczny, pomoże Ci rozwiązać każdy problem, pokaże najlepsze drogi działania i podpowie jak uniknąć problemów w przyszłości.",

  "plugins_support-expert_cd_base_pro_name": "Indywidualna Opieka Ekspercka+",
  "plugins_support-expert_cd_base_pro_description":
    "Pakiet Indywidualnej Priorytetowej Opieki Eksperckiej. Dodaj do swojej aplikacji możliwość korzystania z profesjonalnej pomocy typu PREMIUM. Twój indywidualny konsultant techniczny, pomoże Ci rozwiązać każdy problem priorytetowo. Będzie dostępny dla Ciebie w rozszerzonym okresie, a każdą sprawę rozwiąże w trybie przyśpieszonym.",

  "plugins_easy-going_cd_base_standard_name": "Pakiet Easy Going",
  "plugins_easy-going_cd_base_standard_description":
    "Obsługuj nieograniczoną liczbę klientów, odblokuj pełne możliwości operacyjne Calendesk i pozwól aplikacji, by wspomagała Cię w rozwoju biznesu. Oraz konsekwentnym zwiększaniu zysków.",

  "plugins_alphanumeric-sms-sender_cd_base_standard_name":
    "Własna nazwa nadawcy SMS",
  "plugins_alphanumeric-sms-sender_cd_base_standard_description":
    "Twoja własna nazwa nadawcy SMS we wiadomościach do klientów. Zamień nudny i losowy numer telefonu na własną nazwę i pokaż się z najlepszej strony. Własną nazwę ustawisz w zakładce Profil -> Twoja firma.",

  "plugins_alphanumeric-sms-sender_cd_base_pro_name":
    "Własna nazwa nadawcy SMS",
  "plugins_alphanumeric-sms-sender_cd_base_pro_description":
    "Twoja własna nazwa nadawcy SMS we wiadomościach do klientów. Zamień nudny i losowy numer telefonu na własną nazwę i pokaż się z najlepszej strony. Własną nazwę ustawisz w zakładce Profil -> Twoja firma.",

  plans_thank_you_page_title:
    "Twój plan oraz wybrane pakiety dodatkowe zostały aktywowane!",
  plans_thank_you_page_title_part_1:
    "Twój plan oraz wybrane pakiety dodatkowe zostały",
  plans_thank_you_page_title_part_2: "aktywowane!",
  plans_thank_you_page_subtitle:
    "Możesz je włączać lub wyłączać w dowolnej chwili w zakładce „Twoje pakiety”.",
  plans_thank_you_page_button: "Rozpocznij pracę z Calendesk",
  fill_invoice_data: "Uzupełnij dane do faktury.",

  cancel_plan_confirm_button_1: "Ok, zostaję!",
  cancel_plan_confirm_button_2: "Anuluj plan :(",
  purchase_remove: "Usuń",
  wb_align_center: "Wyrównaj do środka",
  wb_container_fluid: "Pełna szerokość sekcji",
  wb_image_to_right: "Zdjęcie do prawej",
  wb_image_width: "Szerokość zdjęcia w pikselach np. 200",
  wb_image_height: "Wysokość zdjęcia w pikselach np. 200",
  wb_button_width: "Szerokość przycisku w pikselach np. 200",
  wb_image_contain: "Zmieść całe zdjęcie",
  wb_button_elevation: "Cień (poziom) np. 3",
  wb_height: "Wysokość sekcji",
  wb_image_hide_on_mobile: "Ukryj zdjęcie na smartfonach",
  wb_buttons_position_title: "Pozycja przycisków",
  wb_form_position_title: "Pozycja formularza",
  wb_image_position_title: "Pozycja zdjęć",
  wb_position_top_right_title: "Góra prawa strona",
  wb_position_top_left_title: "Góra lewa strona",
  wb_position_top_center_title: "Góra środek",
  wb_position_bottom_right_title: "Dół prawa strona",
  wb_position_bottom_left_title: "Dół lewa strona",
  wb_position_bottom_center_title: "Dół środek",
  wb_create_new_website: "Zacznij od początku",
  wb_image_hide: "Ukryj zdjęcie",
  stripe_is_not_available_in_your_plan:
    "Płatności Stripe są niedostępne w planie Lite. Zwiększ plan na Standard lub Pro.",
  fakturownia_is_not_available_in_your_plan:
    "Fakturownia jest niedostępna w planie Lite. Zwiększ plan na Standard lub Pro.",
  help: "Pomoc",
  upselling: "Sprzedaż dodatkowa",
  simple_store_product_list: "Produkty",
  simple_store_product_transaction_list: "Transakcje",
  simple_store_product_list_product_name: "Nazwa",
  simple_store_product_list_product_price: "Cena",
  no_simple_store_product_info:
    "Nie masz jeszcze dodanych produktów, kliknij poniżej, aby dodać pierwszy.",
  no_subscriptions_info:
    "Nie masz jeszcze dodanych subskrypcji, kliknij poniżej, aby dodać pierwszą.",
  simple_store_product_add_title: "Dodaj produkt",
  subscriptions_add_title: "Dodaj subskrypcję",
  subscriptions_update_title: "Zaktualizuj subskrypcję",
  no_simple_store_product_transaction_info:
    "Nie zarejestrowałeś jeszcze sprzedaży żadnych produktów. Jeśli produkty są już dodane, możesz poniżej przypisać sprzedaż dowolnego z nich wybranemu klientowi.",
  simple_store_product_update_title: "Edytuj produkt",
  wb_button_edit_menu_simple_store_product: "Wybierz produkt",
  wb_button_edit_menu_booking: "Wybierz usługę",
  wb_cta_button_type_1: "Przekierowanie",
  wb_cta_button_type_2: "Rezerwacja",
  wb_cta_button_type_3: "Produkt",
  wb_cta_button_type_4: "Subskrypcja",
  simple_store_product: "Produkt",
  booking_payments: "Rezerwacje",
  payment_type_select_box: "Wybierz rodzaj płatności",
  wb_round_zero_title: "Brak",
  wb_round_sm_title: "Małe",
  wb_round_md_title: "Średnie",
  wb_round_lg_title: "Duże",
  wb_round_xl_title: "XL",
  wb_round_pill_title: "Tabletka",
  wb_round_circle_title: "Koło",
  wb_image_rounded: "Zaokrąglenie zdjęcia",
  wb_gallery_image_number: "Liczba zdjęć w galerii (komputer)",
  wb_gallery_mobile_image_number: "Liczba zdjęć w galerii (smartfon)",
  wb_gallery_width: "Szerokość galerii w procentach np. 50",
  wb_gallery_height: "Wysokość galerii w pikselach np. 200",
  wb_gallery_distance: "Odstęp pomiędzy zdjęciami",
  wb_image_zoom_on_click: "Powiększ zdjęcie po kliknięciu",
  wb_gallery_style: "Styl galerii",
  wb_gallery_style_1_title: "Styl 1",
  wb_gallery_style_2_title: "Styl 2",
  wb_gallery_style_3_title: "Styl 3",
  help_center_title:
    "Dowiedz się więcej na temat funkcji Calendesk, oglądając krótkie filmy.",
  help_center_group_1: "Wprowadzenie",
  help_center_group_2: "System rezerwacji",
  help_center_group_3: "Klienci i powiadomienia",
  help_center_group_4: "Kreator stron www",
  help_center_group_5: "Aplikacja mobilna",
  help_center_group_6: "Płatności",
  help_center_group_1_video_1_title:
    "Wprowadzenie do Calendesk - szybkie omówienie wszystkich funkcji",
  help_center_group_1_video_2_title: "Uzupełnij dane swojej firmy",
  help_center_group_2_video_1_title:
    "Pracownicy - jak dodać nową osobę, która będzie przyjmować rezerwacje?",
  help_center_group_2_video_2_title:
    "Usługi - czym są oraz jak dodać nową usługę do systemu rezerwacji?",
  help_center_group_2_video_3_title: "Usługi - jak zarządzać listą usług?",
  help_center_group_2_video_4_title:
    "Kalendarz - jak zarządzać kalendarzem rezerwacji?",
  help_center_group_2_video_5_title:
    "Kalendarz - wydarzenia niezwiązane z wizytą klienta",
  help_center_group_2_video_6_title:
    "Kalendarz - ograniczenia kalendarza klientów",
  help_center_group_3_video_1_title:
    "Jak zarządzać klientami w Calendesk? Wysyłanie powiadomień do klientów oraz newsletter.",
  help_center_group_3_video_2_title:
    "Personalizacja wiadomości e-mail, SMS oraz push notyfikacji",
  help_center_group_4_video_1_title:
    "Własna strona www - wprowadzenie do kreatora Calendesk",
  help_center_group_4_video_2_title:
    "Własna strona www - omówienie podstawowych funkcjonalności",
  help_center_group_4_video_3_title: "Własna strona www - nagłówek oraz stopka",
  help_center_group_4_video_4_title:
    "Własna strona www - zamiana nagłówka oraz stopki",
  help_center_group_4_video_5_title: "Własna strona www - jak dodawać sekcje?",
  help_center_group_4_video_6_title: "Własna strona www - jak ustawić domenę?",
  help_center_group_5_video_1_title:
    "Aplikacja mobilna z systemem rezerwacji - omówienie kreatora",
  help_center_group_5_video_2_title:
    "Aplikacja mobilna z systemem rezerwacji - prezentacja aplikacji w App Store lub Google Play",
  help_center_group_6_video_1_title:
    "Jak połączyć płatności Stripe z systemem rezerwacji oraz sklepem?",
  help_center_group_6_video_2_title: "Jak włączyć obsługę Przelewy24 w Stripe?",
  help_center_group_6_video_3_title:
    "Jak pobierać opłaty online za Twoje usługi?",
  help_center_group_6_video_4_title:
    "Prosty sklep online w Calendesk - jak pobierać opłaty za produkty online?",
  help_center_group_1_video_1_description:
    "W tym wideo dowiesz się jakie możliwości daje Calendesk. Tutaj przedstawimy strukturę całej platformy. Dzięki temu przewodnikowi dowiesz się, jak poruszać się po poszczególnych modułach Calendesk.",
  help_center_group_1_video_2_description:
    "Dane Twojej firmy mogą pojawić się na Twojej stronie www oraz aplikacji mobilnej. Z tej części dowiesz się gdzie uzupełnić dane Twojej firmy, w którym miejscu ustawić godziny otwarcia Twojego miejsca pracy, gdzie dodać linki do mediów społecznościowych oraz jak wygenerować Regulamin i Politykę Prywatności.",
  help_center_group_2_video_1_description:
    "Z tego filmu dowiesz się jak skonfigurować system rezerwacji. Tutaj powiemy więcej o dodawaniu pracowników, nadawaniu im uprawnień oraz o konfiguracji ich kont w Calendesk.",
  help_center_group_2_video_2_description:
    "W tej części przewodnika powiemy jak dodać i skonfigurować usługi. Tu dowiesz się jak dodać czas trwania do usług, przypisać im ceny oraz jak je opisać.",
  help_center_group_2_video_3_description:
    "Lista usług to miejsce, z którego z łatwości możesz zarządzać Twoimi usługami oraz dodawać nowe.",
  help_center_group_2_video_4_description:
    "Tutaj dowiesz się jak korzystać z kalendarza Calendesk. W tym filmie wyjaśniamy jak filtrować rezerwacje za pomocą wyboru pracownika oraz jak zarządzać rezerwacjami.",
  help_center_group_2_video_5_description:
    "Co zrobić, gdy pracownik idzie na urlop? Z tego filmu dowiesz się jak korzystać z pola wyboru „Wydarzenie niezwiązane z wizytą klienta”.",
  help_center_group_2_video_6_description:
    "Uprawnienia administratora dają Ci nieograniczone możliwości w zakresie zarządzania kalendarzem. Dowiedz się więcej z tego filmu.",
  help_center_group_3_video_1_description:
    "Dzięki tej części dowiesz się jakie narzędzia pozwolą Ci na utrzymanie dobrych relacji z klientem oraz jak zarządzać klientami w platformie Calendesk.",
  help_center_group_3_video_2_description:
    "Dowiedz się jak korzystać z powiadomień oraz jak je modyfikować. To świetne narzędzie służące do komunikacji z klientami i pracownikami!",
  help_center_group_4_video_1_description:
    "Sprawdź jak poruszać się po naszym kreatorze stron www, aby stworzyć w pełni spersonalizowaną witrynę, za pomocą której Twoi klienci będą rezerwowali u Ciebie wizyty. ",
  help_center_group_4_video_2_description:
    "Zobacz, jak przebiega personalizacja Twojej strony www. Budowanie wizerunku w internecie może być zdecydowanie przyjemniejsze, niż Ci się wydawało!",
  help_center_group_4_video_3_description:
    "Dowiedz się więcej o najważniejszych sekcjach, z których zbudujesz swoją stronę www.",
  help_center_group_4_video_4_description:
    "Naucz się zamienić nagłówek oraz stopkę w kreatorze stron www",
  help_center_group_4_video_5_description:
    "Sekcje w kreatorze stron www. Naucz się dodawać sekcje oraz jak nimi zarządzać.",
  help_center_group_4_video_6_description:
    "W tym filmie opisujemy proces dodawania własnej domeny. Dobra domena to klucz do sukcesu Twojej firmy. Zobacz jak ustawić swój adres w sieci.",
  help_center_group_5_video_1_description:
    "Aplikacja mobilna z systemem rezerwacji to coś, czego pozazdroszczą Ci wszyscy. Zobacz jak stworzyć własną aplikację i umieścić ją w sklepi App Store oraz Google Play.",
  help_center_group_5_video_2_description:
    "Zobacz, jak wygląda ostatecznie Twoja aplikacja mobilna w sklepie. W tym filmie dowiesz się jak to zrobić.",
  help_center_group_6_video_1_description:
    "System rezerwacji z płatnościami to coś, czego potrzebuje nowoczesny biznes. Zobacz jak połączyć konto Stripe z kontem Calendesk.",
  help_center_group_6_video_2_description:
    "Płatności Stripe oferują kilka dodatkowych opcji konfiguracji. Zobacz jak włączyć dodatkowe płatności przez operatora Przelewy24.",
  help_center_group_6_video_3_description:
    "Zobacz jak pobierać opłaty za Twoje usługi w Calendesk. Powiemy tutaj o przedpłatach za wizytę, statusach płatności oraz zobaczysz gdzie szukać transakcji za płatności.",
  help_center_group_6_video_4_description:
    "Prosty sklep online w Calendesk? To możliwe! Zobacz jak podłączyć Twoje produkty, za które pobierasz jednorazowe płatności ze swoją stroną www.",
  related_articles: "Powiązane artykuły",
  article_1_title: "Platforma Calendesk – przegląd",
  article_2_title: "Profil – Twoja firma",
  article_3_title: "Pracownicy – dodawanie pracownika",
  article_4_title: "Usługi – dodaj kategorię",
  article_5_title: "Usługi – dodaj usługę",
  article_6_title: "Usługi – lista usług",
  article_7_title: "Pracownicy – kalendarz",
  article_8_title: "Klienci – lista klientów",
  article_9_title: "Klienci – dodaj klienta",
  article_10_title: "Klienci – rezerwacje",
  article_11_title: "Klienci – newsletter",
  article_12_title: "Klienci – powiadomienia",
  article_13_title: "Ustawienia – powiadomienia",
  article_14_title: "Dodatki – strona www",
  article_15_title: "Strona www – personalizacja",
  article_16_title: "Strona www – domena",
};
